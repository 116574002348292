var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table table-striped mb-4"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "9%"
    }
  }, [_vm._v("Start Date")]), _vm.rates.some(function (rate) {
    return rate.calculatedStartDate;
  }) ? _c('th') : _vm._e(), _c('th', {
    attrs: {
      "width": "9%"
    }
  }, [_vm._v("End Date")]), _vm.rates.some(function (rate) {
    return rate.calculatedEndDate;
  }) ? _c('th') : _vm._e(), _c('th', {
    attrs: {
      "width": "3%"
    }
  }, [_vm._v("Consumption")]), _vm.rates.some(function (rate) {
    return rate.calculatedConsumption;
  }) ? _c('th') : _vm._e(), _c('th', {
    attrs: {
      "width": "7%"
    }
  }, [_vm._v("Unit Rate")]), _vm.rates.some(function (rate) {
    return rate.calculatedUnitRate;
  }) ? _c('th') : _vm._e(), _c('th', {
    attrs: {
      "width": "7%"
    }
  }, [_vm._v("Cost")]), _vm.rates.some(function (rate) {
    return rate.calculatedCost;
  }) ? _c('th') : _vm._e(), _vm.rates.some(function (rate) {
    return rate.friendlyStartRead;
  }) ? _c('th', {
    attrs: {
      "width": _vm.editMode ? '35%' : '20%'
    }
  }, [_vm._v("Reads")]) : _vm._e(), _vm.rates.some(function (rate) {
    return rate.calculatedStartRead;
  }) ? _c('th') : _vm._e(), _vm.rates.some(function (rate) {
    return rate.calculatedEndRead;
  }) ? _c('th') : _vm._e(), _vm.editMode ? _c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Actions")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.filteredRates, function (rate) {
    return _c('tr', {
      key: rate.id
    }, [_c('td', [_vm.editMode ? _c('div', [_c('FormItem', {
      attrs: {
        "id": rate.id,
        "type": "text",
        "value": rate.rateName
      },
      on: {
        "input": function (input) {
          return _vm.onChangeRate(input, rate.id, 'rateName');
        }
      }
    })], 1) : _c('div', [_vm._v(_vm._s(!rate.rateName ? 'N/A' : rate.rateName))])]), _c('td', {
      staticClass: "border-left"
    }, [_vm.editMode ? _c('div', [_c('FormItem', {
      attrs: {
        "id": rate.id,
        "type": "datePicker",
        "value": rate.startDate
      },
      on: {
        "input": function (input) {
          return _vm.onChangeRate(input, rate.id, 'startDate');
        }
      }
    })], 1) : _c('div', [_vm._v(_vm._s(rate.friendlyStartDate === null ? 'N/A' : rate.friendlyStartDate))])]), rate.calculatedStartDate ? _c('td', {
      staticClass: "font-w600 text-info"
    }, [_vm._v(_vm._s(rate.calculatedStartDate))]) : _vm._e(), _c('td', {
      staticClass: "border-left"
    }, [_vm.editMode ? _c('div', [_c('FormItem', {
      attrs: {
        "id": rate.id,
        "type": "datePicker",
        "value": rate.endDate
      },
      on: {
        "input": function (input) {
          return _vm.onChangeRate(input, rate.id, 'endDate');
        }
      }
    })], 1) : _c('div', [_vm._v(_vm._s(rate.friendlyEndDate === null ? 'N/A' : rate.friendlyEndDate))])]), rate.calculatedEndDate ? _c('td', {
      staticClass: "font-w600 text-info"
    }, [_vm._v(_vm._s(rate.calculatedEndDate))]) : _vm._e(), _c('td', {
      staticClass: "border-left"
    }, [_vm.editMode ? _c('div', [_c('FormItem', {
      attrs: {
        "id": rate.id,
        "type": "text",
        "value": rate.friendlyConsumption
      },
      on: {
        "input": function (input) {
          return _vm.onChangeRate(input, rate.id, 'consumption');
        }
      }
    })], 1) : _c('div', [_vm._v(_vm._s(rate.friendlyConsumption === null ? 'N/A' : rate.friendlyConsumption))])]), rate.calculatedConsumption ? _c('td', {
      staticClass: "font-w600 text-info"
    }, [_vm._v(_vm._s(rate.calculatedConsumption))]) : _vm._e(), _c('td', {
      staticClass: "border-left"
    }, [_vm.editMode ? _c('div', [_c('FormItem', {
      attrs: {
        "id": rate.id,
        "type": "text",
        "value": rate.friendlyUnitRate
      },
      on: {
        "input": function (input) {
          return _vm.onChangeRate(input, rate.id, 'unitRate');
        }
      }
    })], 1) : _c('div', [_vm._v(_vm._s(rate.friendlyUnitRate === null ? 'N/A' : rate.friendlyUnitRate))])]), rate.calculatedUnitRate ? _c('td', {
      staticClass: "font-w600 text-info"
    }, [_vm._v(_vm._s(rate.calculatedUnitRate))]) : _vm._e(), _c('td', {
      staticClass: "border-left"
    }, [_vm.editMode ? _c('div', [_c('FormItem', {
      attrs: {
        "id": rate.id,
        "type": "text",
        "value": rate.friendlyCost
      },
      on: {
        "input": function (input) {
          return _vm.onChangeRate(input, rate.id, 'cost');
        }
      }
    })], 1) : _c('div', [_vm._v(_vm._s(rate.friendlyCost === null ? 'N/A' : rate.friendlyCost))])]), rate.calculatedCost ? _c('td', {
      staticClass: "font-w600 text-info"
    }, [_vm._v(_vm._s(rate.calculatedCost))]) : _vm._e(), _vm.rates.some(function (rate) {
      return rate.friendlyStartRead;
    }) ? _c('td', {
      staticClass: "border-left"
    }, _vm._l(_vm.rateReads(rate), function (rateRead) {
      var _vm$unitOptions$find;
      return _c('div', {
        key: rateRead.id,
        staticClass: "row"
      }, [_c('div', {
        staticClass: "form-group",
        class: _vm.displayRegister ? 'col-5' : 'col-7'
      }, [_c('div', [_c('label', [_vm._v(_vm._s(rateRead.label))]), _vm.editMode ? _c('div', {
        staticClass: "input-group"
      }, [_c('FormItem', {
        attrs: {
          "id": rateRead.id,
          "type": "text",
          "value": rateRead.value
        },
        on: {
          "input": function (input) {
            return _vm.onChangeRate(input, rateRead.id, rateRead.type);
          }
        }
      }), _c('div', {
        staticClass: "input-group-append"
      }, [_c('FormItem', {
        attrs: {
          "id": rateRead.id,
          "options": _vm.unitOptions,
          "type": "select",
          "is-alt": true
        },
        on: {
          "input": function (input) {
            return _vm.onChangeRate(input, rateRead.id, `${rateRead.type}Unit`);
          }
        },
        model: {
          value: rateRead.unit,
          callback: function ($$v) {
            _vm.$set(rateRead, "unit", $$v);
          },
          expression: "rateRead.unit"
        }
      })], 1)], 1) : _c('div', [_vm._v(_vm._s(rateRead.value || 'N/A') + " " + _vm._s((_vm$unitOptions$find = _vm.unitOptions.find(function (unit) {
        return unit.value === rateRead.unit;
      })) === null || _vm$unitOptions$find === void 0 ? void 0 : _vm$unitOptions$find.label))])])]), _c('div', {
        staticClass: "form-group",
        class: _vm.displayRegister ? 'col-3' : 'col-5'
      }, [_c('label', [_vm._v(" Type ")]), _vm.editMode ? _c('div', {
        staticClass: "input-group"
      }, [_c('FormItem', {
        attrs: {
          "id": rateRead.id,
          "type": "text",
          "value": rateRead.readType
        },
        on: {
          "input": function (input) {
            return _vm.onChangeRate(input, rateRead.id, `${rateRead.type}Type`);
          }
        }
      })], 1) : _c('div', [_vm._v(_vm._s(rateRead.readType || 'N/A'))])]), _vm.displayRegister ? _c('div', {
        staticClass: "form-group col-3"
      }, [_c('label', [_vm._v(" Register ")]), _vm.editMode ? _c('div', {
        staticClass: "input-group"
      }, [_c('FormItem', {
        attrs: {
          "id": rateRead.id,
          "type": "select",
          "value": rateRead.register,
          "options": _vm.registerIds
        },
        on: {
          "input": function (input) {
            return _vm.onChangeRate(input, rateRead.id, `${rateRead.type}Register`);
          }
        }
      })], 1) : _c('div', [_vm._v(_vm._s(rateRead.register || 'N/A'))])]) : _vm._e()]);
    }), 0) : _vm._e(), _vm.editMode ? _c('td', {
      staticClass: "border-left text-center"
    }, [_c('div', {
      staticClass: "btn btn-link text-danger",
      on: {
        "click": function () {
          return _vm.onClickDeleteRate(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times"
    })])]) : _vm._e()]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }